<template>
    <div>
    
        <b-modal id="modal-gameshistory" ref="gameshistorymodal" title="Games History">
            <p class="my-3">Delete a Tournament. If you NOT want a tournament to be part of your Rank... then delete it..</p>
    
            <b-button v-on:click="getGameshistory()" variant="primary" size="sm" class="mx-1">History 3M</b-button>

            <div v-show="loading" class="text-center mt-5">
              <b-spinner variant="primary"></b-spinner>
            </div>

            <div>
                <b-table striped hover small :items="tournamentHistory" :fields="fields" label-sort-asc="" label-sort-desc="" label-sort-clear="">

                <template #cell(deleteoption)="row">
                    <!--b-button size="sm"   @click="deleteTournament(row.item._id)" class="mr-2"-->
                    <b-button size="sm"  variant="primary"  @click="confirmDelete(row.item._id)" class="mr-2">    
                        
                    <b-icon icon="trash" ></b-icon>  
                    </b-button>
                </template>
                </b-table>
            </div>
    
        </b-modal>
    </div>
</template>
     

<script>
import RestService from '@/services/RestService.js'


export default {
    name: "gameshistory",


    data() {
        return {
            loading:false,
            tournamentHistory:[],
            boxOne:'',
            fields: [
            {
                key: 'timeComputed',
                label: 'Date',
                sortable: true,                            
            },
            {
                key: 'name',
                label: 'Name',
                sortable: true,                            
            },
            // {
            //     key: 'typeComputed',
            //     label: 'type',
            //     sortable: true,                            
            // },
            {
                key: 'playersComputed',
                label: 'Players',
                sortable: false,                            
            },
            {
                key: 'deleteoption',
                label: 'Delete',
            }
            
            
            ],
        };
    },
    methods: {
        confirmDelete(id) {
            this.boxOne = ''
            this.$bvModal.msgBoxConfirm('Are you sure you will delete the Tournament?', {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Delete',
                cancelTitle: 'Cancel',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
            .then(value => {
                this.boxOne = value
                console.log("dialog value = " +value )
                if (value) {
                    this.deleteTournament(id)
                }                
            })
            .catch(err => {
                // An error occurred
                console.log("Error"+err)
            })
        },
        adjustPlayers() {        
            for (let i=0; i<this.tournamentHistory.length; i++) {
                let myPlayers = ""
                for (let j=0; j<this.tournamentHistory[i].players.length; j++) {
                    let temp = this.tournamentHistory[i].players[j].name
                    //myPlayers.push(temp)
                    myPlayers = myPlayers + "" + temp + ", "
                }
                myPlayers = myPlayers.slice(0, -2); 
                this.tournamentHistory[i].playersComputed = myPlayers
                
                const date = new Date(this.tournamentHistory[i].time)
                const year = date.getFullYear();
                const month = `0${date.getMonth() + 1}`.slice(-2);
                const day = `0${date.getDate()}`.slice(-2);
                const formattedDate = `${year}-${month}-${day}`;
                this.tournamentHistory[i].timeComputed = formattedDate
                let typeTemp = this.tournamentHistory[i].type.substring(0,3)                
                this.tournamentHistory[i].typeComputed = typeTemp.charAt(0).toUpperCase() + typeTemp.slice(1);
                
            }                    
        },
        async getGameshistory(history) {
            this.loading = true;            
            try {
                let mytoken = this.$cookies.get('lop-jwt');
                let responseGameshistory = await RestService.getGameshistory(mytoken, history)     
                //let Tournamnets = responseGameshistory.data
                this.tournamentHistory = responseGameshistory.data
                this.adjustPlayers()
                console.log("responseGameshistory=" +responseGameshistory)                           
            } catch (error) {
                console.log("Can not load game history")
            }
            this.loading = false;
        },
        async deleteTournament(id) {
            console.log("Delete tournament id= " +id)
            this.loading = true;            
            try {
                let mytoken = this.$cookies.get('lop-jwt');      
                this.tournamentHistory = [];          
                await RestService.deleteTournament(mytoken, id)                                                     
            } catch (error) {
                console.log("Can not delete tournament")
            }
            
            this.loading = false;
            this.getGameshistory()

        }



    },


    props: {
        email: String,


    },
    computed: {
        myGroupsNotUsed: function() {
            let groups = []
            return groups;
        },
        testComp:function(players) {
            return players.name
        }


    },
    mounted() {
        console.log("MOUNTING GAMES HISTORY")
    },

    watch: {},
};
</script>

<style scoped>

</style>

